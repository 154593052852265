import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'aos/dist/aos.css'
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
ReactDOM.render(
  <React.StrictMode>
  
       <BrowserRouter>
    <App />
    </BrowserRouter>
   
  </React.StrictMode>,
  document.getElementById('root')
);

const favicon180Url = process.env.REACT_APP_FAVICON_180_URL;
const favicon32Url = process.env.REACT_APP_FAVICON_32_URL;
const favicon16Url = process.env.REACT_APP_FAVICON_16_URL;

const createFaviconLink = (rel, sizes, href, type = 'image/png') => {
  const link = document.createElement('link');
  link.rel = rel;
  link.sizes = sizes;
  link.href = href;
  if (type) link.type = type;
  document.head.appendChild(link);
};

if (favicon180Url) {
  createFaviconLink('apple-touch-icon', '180x180', favicon180Url, null);
}

if (favicon32Url) {
  createFaviconLink('icon', '32x32', favicon32Url);
}

if (favicon16Url) {
  createFaviconLink('icon', '16x16', favicon16Url);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

