import React, { useEffect, useState } from "react";
import "./MyBooking.scss";
import Navbar from "../../Component/Navbar/navbar";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import axios from "axios";
import { BASE_URL } from "../../environments/config";
import { Helmet } from "react-helmet";
import seoData from "../../Seo";
export default function MyBooking() {
  const pageUrl = seoData["mybookings"]?.PageURL;
  const metaTitle = seoData["mybookings"]?.MetaTitle;
  const metaKeywords = seoData["mybookings"]?.Keywords;

  const [bookingList, setBookingList] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [totalPrices, setTotalPrices] = useState([]);
  const [inputPayment, setInputPayment] = useState(0);
  const [payment, setPayment] = useState("full");
  const [errorMessage, setErrorMessage] = useState("");
  const [remaining, setRemaining] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [bookingId, setBookingId] = useState(null);
  const [user, setUser] = useState({});
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchBookingList = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/booking/assigned-bookings?userId=${userId}`
        );
        setBookingList(response.data.data);
      } catch (err) {
        console.log("err", err);
      }
    };

    if (userId) {
      fetchBookingList();
    }
  }, [userId]);

  useEffect(() => {
    if (bookingList.length > 0) {
      const prices = bookingList.map((booking) => {
        return (
          ((booking.puja_model.pujariCost +
            booking.puja_model.samagriCost +
            booking.puja_model.serviceFee) *
            18) /
            100 +
          (booking.puja_model.pujariCost +
            booking.puja_model.samagriCost +
            booking.puja_model.serviceFee)
        );
      });
      setTotalPrices(prices);
    }
  }, [bookingList]);

  const toggleDetails = (index) => {
    setShowDetails(showDetails === index ? null : index);
  };

  const handleProceed = (totalPrice, index) => {
    const amountToPay = inputPayment[index] || totalPrice;

    if (amountToPay > 0) {
      const minPayment = totalPrice * 0.1;
      if (parseFloat(amountToPay) < minPayment) {
        setErrorMessage(
          `Minimum payment of 10% (₹${parseInt(minPayment)}) is required.`
        );
        return;
      } else {
        setErrorMessage("");
        paymentHandler(totalPrice, amountToPay);
      }
    }
  };

  const getTransaction = async (booking_id) => {
    setBookingId(booking_id);
    try {
      const response = await axios.get(
        `${BASE_URL}/booking-transaction?booking_id=${booking_id}`
      );

      const transactions = response.data.data;
      setTransaction(transactions);

      const currentBooking = bookingList.find(
        (booking) => booking.id === booking_id
      );
      const totalAmount = currentBooking.bookingSellingPrice;

      const totalPaidAmount = transactions.reduce(
        (sum, transaction) => sum + transaction.paidAmount,
        0
      );

      const remainingAmount = Math.max(totalAmount - totalPaidAmount, 0);

      setRemaining(remainingAmount);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const response = await axios.get(`${BASE_URL}/users`);
      const users = response.data.data;
      const user = Object.values(users).find((user) => user.id === userId);
      setUser(user);
    };
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const paymentHandler = async (totalPrice, amountToPay) => {
    const BASE_URL = process.env.REACT_APP_PAYMENT_INTEGRATION_URL;
    const IMAGE_URL = process.env.REACT_APP_PAYMENT_IMAGE;

    try {
      const response = await fetch(`${BASE_URL}/orders/payment`, {
        method: "POST",
        body: JSON.stringify({
          amount: amountToPay * 100,
          currency: "INR",
          user_id: userId,
          program_registration_id: 1,
          billing_name: user.name,
          billing_address: user.addressLine1,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const order = await response.json();

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: "karishye",
        description: "Test Transaction",
        image: IMAGE_URL,
        order_id: order.id,
        handler: async function (response) {
          const razorpay_id = response.razorpay_payment_id;
          const transaction_id = response.razorpay_order_id;
          const paidAmount = order.amount / 100;

          const payload = {
            booking_id: bookingId,
            transaction_id: transaction_id,
            razorpay_id: razorpay_id,
            paidAmount: paidAmount,
            totalAmount: totalPrice,
            payment_status: "success",
            payment_type: "website",
          };

          await axios.post(`${BASE_URL}/transaction`, payload);
          getTransaction(bookingId);
          alert("Payment successful!");
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });

      rzp1.on("modal.closed", function () {
        document.body.style.overflow = "auto";
      });

      rzp1.open();

      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error("Payment initiation error:", error);
      document.body.style.overflow = "auto";
    }
  };

  function calculateTotalCost(samagriData) {
    return samagriData.reduce((total, item) => {
      if (item.isProvidedBy !== "user") {
        return total + item?.samagri?.customerMrp * item.qty;
      }
      return total;
    }, 0);
  }

  const handleInputChange = (e, index) => {
    setInputPayment({
      ...inputPayment,
      [index]: e.target.value,
    });
  };

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Navbar />
      <div className="newofferingsec mybooking-sec">
        <div className="container">
          <h1>My Bookings</h1>
          <div className="my-bookings-wrapper">
            <div className="bookings-box">
              <div className="accordion" id="myBookingsAccord"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-booking-container">
        <div className="accordion">
          {bookingList.map((list, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <div className="accordion-button collapsed my-booking-pujaname collapse-btn">
                  <div className="header-wrapper">
                    <div className="title-wrapper">
                      <img
                        src="assets/images/Karishye-logomybooking.png"
                        alt=""
                      />
                      <p className="booking-puja-name">
                        {list?.puja_model?.puja?.name}
                      </p>
                    </div>
                    <div>
                      <img
                        className="collapse-booking-details"
                        src="assets/images/down-icon.png"
                        alt=""
                        style={{
                          transform:
                            showDetails === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                        onClick={() => {
                          toggleDetails(index);
                          getTransaction(list?.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </h2>
              <div className="row mybooking-top-details">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="booking-details-box">
                    <h6>
                      <span>
                        <img src="assets/images/user.png" alt="" />
                      </span>
                      {list?.name ? (
                        list.name
                      ) : list?.user?.name ? (
                        list.user.name
                      ) : (
                        <i>To Be Decided</i>
                      )}
                    </h6>
                    <p>Name</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="booking-details-box">
                    <h6>
                      <span>
                        <img src="assets/images/user.png" alt="" />
                      </span>
                      {list?.pujaris &&
                      list?.pujaris.name &&
                      list?.bookingStatus !== "pujari_rejected" ? (
                        list?.pujaris.name
                      ) : (
                        <i>To Be Decided</i>
                      )}
                    </h6>
                    <p>Pujari Name</p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                  <div className="booking-details-box">
                    <h6>
                      <span>
                        <img src="assets/images/rupee.png" alt="" />
                      </span>
                      {list?.bookingSellingPrice ? (
                        list?.bookingSellingPrice
                      ) : (
                        <i>To Be Decided</i>
                      )}
                    </h6>
                    <p>Final Cost</p>
                  </div>
                </div>
              </div>

              <div
                id="collapse"
                className="accordion-collapse collapse"
                aria-labelledby="mybookingsBtn"
                data-bs-parent="#myBookingsAccord"
                style={{ display: showDetails === index ? "block" : "none" }}
              >
                <div className="accordion-body padding-top-0">
                  <div className="mybookings-body-top">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="booking-details-box">
                          <div className="calender-time-wrap">
                            <h6>
                              <span>
                                <img src="assets/images/calender.png" alt="" />
                              </span>
                              {list?.startDate ? (
                                list?.startDate
                              ) : (
                                <i>To Be Decided</i>
                              )}
                            </h6>
                          </div>
                          <p>Start Date</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="booking-details-box">
                          <div className="calender-time-wrap">
                            <h6>
                              <span>
                                <img src="assets/images/calender.png" alt="" />
                              </span>
                              {list?.endDate ? (
                                list?.endDate
                              ) : (
                                <i>To Be Decided</i>
                              )}
                            </h6>
                          </div>
                          <p>End Date</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="booking-details-box">
                          <h6>
                            <span>
                              <img
                                src="assets/images/phone-orange.png"
                                alt=""
                              />
                            </span>
                            {list?.pujaris?.phoneNumber ? (
                              list?.pujaris?.phoneNumber
                            ) : (
                              <i>To Be Decided</i>
                            )}
                          </h6>
                          <p>Pujari's Number</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="booking-details-box">
                          <h6>
                            <span>
                              <img src="assets/images/marker.png" alt="" />
                            </span>
                            {list?.address ? (
                              list?.address
                            ) : (
                              <i>To Be Decided</i>
                            )}
                          </h6>
                          <p>Location</p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="booking-details-box">
                          <h6>
                            <span>
                              <img src="assets/images/marker.png" alt="" />
                            </span>
                            {list?.eventType ? (
                              list?.eventType
                            ) : (
                              <i>To Be Decided</i>
                            )}
                          </h6>
                          <p>Puja Mode</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mybookings-status">
                <h2>Your Order Status</h2>
                <div className="mybookings-status-wrapper">
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "submitted"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus1.svg" alt="" />
                    </div>
                    <p>Booking Submitted</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "plan_under_review"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus2.svg" alt="" />
                    </div>
                    <p>Booking Under Review</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "plan_approved"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus3.svg" alt="" />
                    </div>
                    <p>Puja Plan Finalised</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "puja_ended"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus5.svg" alt="" />
                    </div>
                    <p>Puja Completed</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "pujari_amount_credited"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus4.svg" alt="" />
                    </div>
                    <p>Payment Completed</p>
                  </div>
                </div>
              </div>

              <div
                className="booking-details-samagri-and-invoice"
                style={{ display: showDetails === index ? "block" : "none" }}
              >
                <div className="right-side-floater" style={{ display: "flex" }}>
                  <div className="my-bookings-invoice">
                    <div className="price-box">
                      <div>
                        <p className="samagri-price-heading">
                          Price breakup
                          <img
                            className="gold-star-line"
                            src="assets/images/gold-star-with-line.png"
                            alt=""
                          />
                          <img
                            className="gold-star-dots"
                            src="assets/images/gold-star-with-dots.png"
                            alt=""
                          />
                        </p>
                      </div>
                    </div>
                    <div className="price-box">
                      <div>
                        <div>
                          <p>Pujari</p>
                        </div>

                        <p className="pujari-cost">₹ {list?.pujariCost}</p>
                      </div>
                    </div>
                    <div className="price-box">
                      <div>
                        <div>
                          <p>Samagri</p>
                        </div>
                        <p className="samagri-cost">
                          ₹ {calculateTotalCost(list?.bookingSamagriMappings)}
                        </p>
                      </div>
                    </div>
                    <div className="price-box service-charges-floater">
                      <div>
                        <div>
                          <p>Karishye charges</p>
                        </div>
                        <p className="service-price">
                          ₹ {list?.puja_model?.serviceFee}
                        </p>
                      </div>
                    </div>

                    <div className="price-box">
                      <div>
                        <div className="total-incl-tax">
                          <p className="total">Total payable</p>
                          <p className="totalSubDetail">
                            (Incl. of all Taxes){" "}
                          </p>
                        </div>
                        <p className="floater-booked-price">
                          ₹ {list?.bookingSellingPrice}
                        </p>
                      </div>
                    </div>
                    {transaction.length === 0 ? (
                      <>
                        <div className="invoice-box">
                          <p className="invoice-payment">
                            <input
                              type="radio"
                              className="radio-yes first"
                              name="muhurtam_fixed"
                              defaultChecked
                              onClick={() => {
                                setPayment("full");
                                setInputPayment(0);
                              }}
                            />
                            Full Payment
                          </p>
                          <p className="invoice-payment">
                            <input
                              type="radio"
                              className="radio-yes"
                              name="muhurtam_fixed"
                              onClick={() => setPayment("advance")}
                            />
                            Pay Advance
                          </p>
                          {payment === "advance" && (
                            <>
                              <input
                                type="number"
                                className="form-control int-fill-input"
                                placeholder="enter amount"
                                onChange={(e) => handleInputChange(e, index)}
                              />
                              <p className="note">
                                Note: A min. payment of 10%{" "}
                              </p>
                              {errorMessage && (
                                <p className="error-message">{errorMessage}</p>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          className="details-book-now"
                          style={{ width: "100%" }}
                        >
                          <a
                            onClick={() =>
                              handleProceed(list?.bookingSellingPrice, index)
                            }
                            className="bookbut"
                          >
                            Proceed
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="remaining">
                          Remaining Amount : {remaining}
                        </div>
                        <div
                          className="details-book-now"
                          style={{ width: "100%" }}
                        >
                          {remaining !== 0 && (
                            <a
                              onClick={() => handleProceed(remaining, index)}
                              className="bookbut"
                            >
                              Proceed
                            </a>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="samagri-popup-outer">
                  <div className="samagri-popup-container">
                    <div className="my-booking-samagri">
                      <div className="samagri-popup-heading-container">
                        <h2>Puja Details</h2>
                      </div>

                      <div className="samagri-list-container">
                        <p className="samagri-heading">
                          Samagri provided by Karishye (Already included in
                          package)
                          <img
                            className="gold-star-line"
                            src="assets/images/gold-star-with-line.png"
                            alt=""
                          />
                          <img
                            className="gold-star-dots"
                            src="assets/images/gold-star-with-dots.png"
                            alt=""
                          />
                        </p>
                        <ul className="samagri-list">
                          {list?.bookingSamagriMappings
                            ?.filter(
                              (samagri) => samagri?.isProvidedBy === "karishye"
                            )
                            ?.map((samagri, index) => (
                              <li key={index}>
                                <span className="samagri-item-name">
                                  {samagri?.samagri?.name}
                                </span>
                                <span className="samagri-item-quantity">
                                  {samagri?.samagri?.standardQty * samagri?.qty}{" "}
                                  {samagri?.samagri?.units}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="samagri-also-needed">
                        <p className="samagri-heading">
                          Add-ons
                          <img
                            className="gold-star-line"
                            src="assets/images/gold-star-with-line.png"
                            alt=""
                          />
                          <img
                            className="gold-star-dots"
                            src="assets/images/gold-star-with-dots.png"
                            alt=""
                          />
                        </p>
                        <div className="samagri-what-you-need-items">
                          <ul>
                            {list?.bookingSamagriMappings
                              ?.filter(
                                (samagri) => samagri?.isProvidedBy === "user"
                              )
                              ?.map((samagri, index) => (
                                <li key={index}>
                                  <img src="assets/images/gold-star.png" />
                                  <span className="samagri-item-name">
                                    {samagri?.samagri?.name}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="procedure-list-container">
                      <p className="samagri-heading">
                        Procedures
                        <img
                          className="gold-star-line"
                          src="assets/images/gold-star-with-line.png"
                          alt=""
                        />
                        <img
                          className="gold-star-dots"
                          src="assets/images/gold-star-with-dots.png"
                          alt=""
                        />
                      </p>
                      <ul className="procedure-list">
                        {list?.bookingproceduremappings?.map((procedure) => (
                          <li>
                            <p className="procedure-title">
                              {procedure.procedure.title}
                            </p>
                            <p className="procedure-description">
                              {procedure.procedure.description}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="samagri-bottom-image-div">
                      <img
                        src="assets/images/special-notes-icon.png"
                        className="special-notes-img"
                        alt=""
                      />
                    </div>
                    {list?.customerNotes && (
                      <div class="booking-special-notes">
                        <h2>Special Notes</h2>
                        <p>{list?.customerNotes}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Whatsapp />
    </div>
  );
}
