const jsonData = {
    "Annaprasana Puja": process.env.REACT_APP_IMAGE_URL + "/annaprasana_puja/image_1.png",
    "Aksharabhyasam": process.env.REACT_APP_IMAGE_URL + "/aksharabhyasam/image_1.png",
    "Arogya Homam": process.env.REACT_APP_IMAGE_URL + "/arogya_homa/image_1.jpg",
    "Ayusha Homam":process.env.REACT_APP_IMAGE_URL + "/ayushya_homam/image_1.jpg",
    "Abdhikam One Bhokta Puja":process.env.REACT_APP_IMAGE_URL + "/abdhikam_one_bhokta/image_1.jpg",
    "Abdhikam Three Bhokta Puja":process.env.REACT_APP_IMAGE_URL + "/abdhikam_three_bhokta/image_1.png",
    "Bhoomi Puja":process.env.REACT_APP_IMAGE_URL + "/bhoomi_puja/image_1.jpg",
    "Chandi Homam":process.env.REACT_APP_IMAGE_URL + "/chandi_homam/image_1.png",
    "Default Puja Image":process.env.REACT_APP_IMAGE_URL + "/default_puja_image.jpg",
    "Ganapati Homam":process.env.REACT_APP_IMAGE_URL + "/ganapati_homam/image_1.jpg",
    "Gruhapravesham":process.env.REACT_APP_IMAGE_URL + "/gruhapravesham/image_1.jpeg",
    "Masikam":process.env.REACT_APP_IMAGE_URL + "/masikam/image_1.png",
    "Rudrabhishekam":process.env.REACT_APP_IMAGE_URL + "/rudrabhishekam/image_1.png",
    "Samvatsarikam":process.env.REACT_APP_IMAGE_URL + "/samvatsrikam/image_1.png",
    "Saraswati Homam":process.env.REACT_APP_IMAGE_URL + "/saraswati_homam/image_1.png",
    "Satyanarayana Vratam":process.env.REACT_APP_IMAGE_URL + "/satyanarayana_vratam/image_1.jpg",
    "Sudarshana Homam":process.env.REACT_APP_IMAGE_URL + "/sudarshana_homam/image_1.jpg"
  }

  export default jsonData;