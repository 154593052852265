import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Component/Navbar/navbar";
import "./LandingPage.scss";
import lamp from "../Static/Image/lamp.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import Whatsapp from "../Component/Whatsapp/Whatsapp";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import jsonData from "../images";
import { BASE_URL } from "../environments/config";
import { Helmet } from 'react-helmet';
import seoData from "../Seo";

export default function LandingPage() {

  const pageUrl = seoData["landingpage"]?.PageURL;
  const metaTitle = seoData["landingpage"]?.MetaTitle;
  const metaDescription = seoData["landingpage"]?.MetaDescription;

  const navigate = useNavigate();
  const [pujaList, setPujaList] = useState([]);
  const [showContactUs, setShowContactUs] = useState(false);
  const [pujaModeData, setPujaModeData] = useState([]);
  const [tab, settab] = useState("Homam");
  const [occ, setOcc] = useState([]);
  const [occList, setOccList] = useState([]);
  const [selectedPujaMode, setSelectedPujaMode] = useState(null);
  const [filter, setFilter] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);
  const [allPujas, setAllPujas] = useState([]);
  const [lifeEventList, setLifeEventList] = useState([]);
  const [navList, setNavList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dropdownRef = useRef(null);
  const sliderRef = useRef(null);

  if (sliderRef.current) {
    sliderRef.current.slickGoTo(0);
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1346,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchOccPujas = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/all-pujas`);
        const occData = response.data.data[1].subCategories;
        setOcc(occData);

        const lifeEventData =
          response.data.data[0].subCategories[0].pujasubcategorymappings;
        setLifeEventList(lifeEventData);

        const selectedOcc = occData.find((i) => i.name === tab);
        if (selectedOcc) {
          setOccList(selectedOcc.pujasubcategorymappings);
        }
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    fetchOccPujas();
  }, [tab]);

  useEffect(() => {
    const fetchPujaModeData = async () => {
      if (!selectedPujaMode) {
        try {
          const response = await axios.get(`${BASE_URL}/pujas`);
          setPujaModeData(response.data.data);
        } catch (err) {
          console.log(err, "err");
        }
      } else {
        try {
          const response = await axios.get(
            `${BASE_URL}/pujas?mode=${selectedPujaMode}`
          );
          setPujaModeData(response.data.data);
        } catch (err) {
          console.log(err, "err");
        }
      }
    };
    fetchPujaModeData();
  }, [selectedPujaMode]);

  const filteredPujaList = pujaModeData.filter((puja) =>
    puja?.name.toLowerCase().includes(filter.toLowerCase())
  );

  const selectPujaMode = (mode) => {
    setSelectedPujaMode(mode);
  };

  const handleInputChange = (e) => {
    setFilter(e.target.value);
    setNavList([]);
  };

  const handleInputClick = () => {
    setIsListVisible(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsListVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const redirection = (item) => {
    localStorage.setItem("pujaId", item?.id);

    if (
      (item.length === 0 && filter && typeof filter === "string") ||
      item?.pujamodels[0]?.isPopularModel === false
    ) {
      setShowContactUs(true);
      return;
    }

    if (item.length === 0) {
      return;
    }

    if (item?.pujamodels[0]?.procedures?.length > 0) {
      localStorage.setItem("ToBooking", false);
      let navigateTo = "poojas/telugu/" + item?.url_name;
      window.open(navigateTo, "_self");
    } else {
      localStorage.setItem("ToBooking", true);
      let navigateTo = "booking/telugu/" + item?.url_name;
      window.open(navigateTo, "_self");
    }
  };
  return (
    <>
        <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Navbar />
      <div className="container">
        <div class="fstcontsec">
          <div class="first-sec-img">
            <div class="centertext">
              <div class="container">
                <div class="row">
                  <div
                    class="col-md-6 header-text"
                    style={{ paddingLeft: "0", width: "100%" }}
                  >
                    <h1>Putting the Spirit back into Rituals</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="book-puja-container">
              <div className="bookpujasec">
                {selectedPujaMode === "at_my_home" && (
                  <>
                    <div
                      className="bookpujasec-header"
                      style={{ display: "block", marginBottom: "10px" }}
                    >
                      We are currently providing service in Telugu language at
                      Hyderabad.
                    </div>
                    <img
                      className="bookpujasec-header-line "
                      style={{ display: "block" }}
                      src="	assets/images/Group-6485.png"
                      alt=" "
                    />
                  </>
                )}
                <div className="pujamodelabel">
                  Which mode you would like to do a puja?{" "}
                </div>
                <div className="pujamodesec">
                  <div
                    className="pujasec"
                    onClick={() => {setFilter("");selectPujaMode("at_my_home")}}   // empty filter when changing the mode
                  >
                    {selectedPujaMode === "at_my_home" ? (
                      <img
                        className="in-person-icon-active"
                        src="assets/images/inperson-icon.png"
                        alt="in-person-icon-active"
                      />
                    ) : (
                      <img
                        className="in-person-icon"
                        src="assets/images/in-person.svg"
                        alt="in-person-icon"
                      />
                    )}
                    <div className="pujasec-label">In Person</div>
                  </div>
                  <div
                    className="pujasec"
                    onClick={() => {setFilter("");selectPujaMode("remote")}}
                  >
                    {selectedPujaMode === "remote" ? (
                      <img
                        className="online-icon-active"
                        src="assets/images/online-active.png"
                        alt="online-icon-active"
                      />
                    ) : (
                      <img
                        className="online-icon"
                        src="assets/images/online-icon.svg"
                        alt="online-icon"
                      />
                    )}

                    <div className="pujasec-label">Online</div>
                  </div>
                  <div
                    className="pujasec pujasec-mbl"
                    onClick={() => {setFilter("");selectPujaMode("on_behalf")}}
                  >
                    {selectedPujaMode === "on_behalf" ? (
                      <img
                        src="assets/images/on-behalf-active.png"
                        alt="onbehalf-icon-active"
                        className="onbehalf-icon-active"
                      />
                    ) : (
                      <img
                        className="onbehalf-icon"
                        src="assets/images/behalf-icon.svg"
                        alt="onbehalf-icon"
                      />
                    )}
                    <div className="pujasec-label">On-behalf</div>
                    {selectedPujaMode === "on_behalf" && (
                      <div
                        className="pujasec-behalf"
                        id="knowMore"
                        style={{
                          display: "block",
                          paddingTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <p className="pujaseckmlabel">
                          <img
                            src="assets/images/info-icon.svg"
                            alt="know more icon"
                            className="know-more-border"
                          />
                          Know more
                        </p>
                        <div className="hover-content">
                          <a>
                            {" "}
                            You are out of station or out of country, however
                            you wish for Karishye pujaris and team to perform
                            the puja on your behalf and send you the videos and
                            photographs{" "}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pujamodelabel">
                  What kind of puja you would like to perform ?
                </div>
                <div className="searchable" ref={dropdownRef}>
                  <input
                    className="form-select pujadisableenable"
                    id="search-puja-input1"
                    type="text"
                    placeholder="Search pujas"
                    value={filter}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                  />
                  {isListVisible && (
                    <ul style={{ display: "block" }}>
                      {filteredPujaList.map((puja, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setFilter(puja.name);
                            setIsListVisible(false);
                            setNavList(puja);
                          }}
                        >
                          {puja.name}
                        </li>
                      ))}
                      <li
                        onClick={() => {
                          navigate("/contact");
                        }}
                      >
                        <span
                          class="not-found-item"
                          onclick="openContactForm()"
                        >
                          Could not find the puja? Simply click here and submit
                          your specific requirements.{" "}
                        </span>
                      </li>
                    </ul>
                  )}
                </div>
                <a
                  className="ritualbut bookbtn"
                  onClick={() => redirection(navList)}
                >
                  Book Puja
                </a>
              </div>
            </div>
            <div
              class="not-found-container"
              style={{ display: showContactUs ? "flex" : "none" }}
            >
              <div class="not-found-text">
                <p>Looking for something else?</p>
                <p>Please reach out to us.</p>
                <p></p>
              </div>
              <div class="not-found-contact">
                <button class="contact-us-button contact-btn-offering">
                  <a href="/contact" previewlistener="true">
                    Contact Us
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div class="lamp-img">
            <img src={lamp} alt="" />
          </div>
        </div>

        <div class="mybookings-status life-event-puja-status padding-top-0 home-page-sections life-event-based-puja-websec ">
          <h3 class="home-headings life-event-home-headings">
            Life Event Based Pujas
          </h3>
        </div>
        <div className="home-page-slider-container home-page-slider-container-main second-sec">
          <div className="life-based-pujas-slider-container">
            <Slider ref={sliderRef} {...settings}>
              {lifeEventList &&
                lifeEventList.map((puja, index) => (
                  <div
                    key={puja.pujaId}
                    className="list"
                    onClick={() => {
                      localStorage.setItem("pujaId", puja.pujaId);
                      let navigateTo = "poojas/telugu/" + puja?.pujas?.url_name;
                      window.open(navigateTo, "_self");
                    }}
                  >
                    <div key={index} className="life-based-card">
                      <div>
                        {jsonData[puja?.pujas?.name] ? (
                          <img
                            src={jsonData[puja?.pujas?.name]}
                            className="puja-image"
                            width="100%"
                            height="100%"
                            alt={`${puja?.pujas?.name}-image`}
                          />
                        ) : (
                          <img
                            src="assets/images/default-no-puja-image.png"
                            className="puja-image"
                            width="100%"
                            height="100%"
                            alt={`${puja?.pujas?.name}-image`}
                          />
                        )}
                      </div>
                      <h4 className="puja-title">{puja?.pujas?.name}</h4>
                      <p className="puja-desc">{puja?.pujas?.description}</p>
                      <img className="gold-line" src="assets/images/gold-line.png" alt="gold-line" />
                      <div className="bottom-price-btn">
                        <button className="puja-book-button">Know More</button>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>

        <a className="home-viewmore" href="/offerings" previewlistener="true">
          <div
            className="home-page-viewmore"
            onClick={() => localStorage.setItem("tab", "Life Event Based")}
          >
            View All
          </div>
        </a>

        <div class="remedial-issues-container">
          <div class="remedial-issues-left">
            <img src="assets/images/remedial-issue-img.png" alt="" />
          </div>
          <div class="remedial-issues-right">
            <h3>Not sure what puja to perform?</h3>
            <p>
              Sometimes we encounter situations where we are aware of what
              problems we have but we cannot identify which puja or action can
              help us overcome the situation. Write to us and our pujari team
              will help out. We will stay connected with you until your issue is
              resolved.
            </p>
            <button class="contact-us-button" onClick="startLoader()">
              <a href="/contact">Contact Us</a>
            </button>
          </div>
        </div>

        <div
          class="mybookings-status padding-top-0 home-page-sections occation-based-event-mblsec"
          style={{ display: "none" }}
        >
          <h2 class="home-headings">Occasion based Pujas</h2>
          <div class="carousel-container occation-based-puja-mblsec">
            <div
              class="puja-card-mblsec"
              onClick={() => {
                localStorage.setItem("tab", "Homam");
                navigate("/offerings");
              }}
            >
              <div class="puja-img-sec">
                <img class="" src="assets/images/homan-icon.png" alt="" />
              </div>
              <h4 class="puja-title-mblsec"> Homam </h4>
            </div>
            <div
              class="puja-card-mblsec"
              onClick={() => {
                localStorage.setItem("tab", "Well Being");
                navigate("/offerings");
              }}
            >
              <div class="puja-img-sec">
                <img class="" src="assets/images/well-being-icon.png" alt="" />
              </div>
              <h4 class="puja-title-mblsec"> Well Being </h4>
            </div>
            <div
              class="puja-card-mblsec"
              onClick={() => {
                localStorage.setItem("tab", "Wish Fulfilment");
                navigate("/offerings");
              }}
            >
              <div class="puja-img-sec">
                <img class="" src="assets/images/wish-full-icon.png" alt="" />
              </div>
              <h4 class="puja-title-mblsec"> Wish Fulfilment </h4>
            </div>
            <div
              class="puja-card-mblsec"
              onClick={() => {
                localStorage.setItem("tab", "Remedial");
                navigate("/offerings");
              }}
            >
              <div class="puja-img-sec">
                <img
                  class=""
                  src="assets/images/remedial-based-icon.png"
                  alt=""
                />
              </div>
              <h4 class="puja-title-mblsec"> Remedial </h4>
            </div>
            <div
              class="puja-card-mblsec"
              onClick={() => {
                localStorage.setItem("tab", "Win over battles");
                navigate("/offerings");
              }}
            >
              <div class="puja-img-sec">
                <img class="" src="assets/images/win-over-icon.png" alt="" />
              </div>
              <h4 class="puja-title-mblsec"> Win over battles </h4>
            </div>
            <div
              class="puja-card-mblsec"
              onClick={() => {
                localStorage.setItem("tab", "Vratam");
                navigate("/offerings");
              }}
            >
              <div class="puja-img-sec">
                <img class="" src="assets/images/vratam-icon.png" alt="" />
              </div>
              <h4 class="puja-title-mblsec"> Vratam </h4>
            </div>
          </div>
        </div>

        <div class="occasion-container">
          <h2 class="home-headings">Occasion based Pujas</h2>
          <div class="occasion-tabs-container">
            <div
              className={
                tab === "Homam" ? "occasion-tab occ-active" : "occasion-tab "
              }
              onClick={() => settab("Homam")}
            >
              <img src="assets/images/homan-icon.png" alt="" />
              <p class="occasion-title">Homam</p>
            </div>
            <div
              className={
                tab === "Well Being"
                  ? "occasion-tab occ-active"
                  : "occasion-tab "
              }
              onClick={() => settab("Well Being")}
            >
              <img src="assets/images/well-being-icon.png" alt="" />
              <p class="occasion-title">Well Being</p>
            </div>
            <div
              className={
                tab === "Wish Fulfilment"
                  ? "occasion-tab occ-active"
                  : "occasion-tab "
              }
              onClick={() => settab("Wish Fulfilment")}
            >
              <img src="assets/images/wish-full-icon.png" alt="" />
              <p class="occasion-title">Wish Fulfilment</p>
            </div>
            <div
              className={
                tab === "Remedial" ? "occasion-tab occ-active" : "occasion-tab "
              }
              onClick={() => settab("Remedial")}
            >
              <img src="assets/images/remedial-based-icon.png" alt="" />
              <p class="occasion-title">Remedial</p>
            </div>
            <div
              className={
                tab === "Win over battles"
                  ? "occasion-tab occ-active"
                  : "occasion-tab "
              }
              onClick={() => settab("Win over battles")}
            >
              <img src="assets/images/win-over-icon.png" alt="" />
              <p class="occasion-title">Win over battles</p>
            </div>
            <div
              className={
                tab === "Vratam" ? "occasion-tab occ-active" : "occasion-tab "
              }
              onClick={() => settab("Vratam")}
            >
              <img src="assets/images/vratam-icon.png" alt="" />
              <p class="occasion-title">Vratam</p>
            </div>
          </div>
        </div>

        <div className="home-page-slider-container occasion-category7">
          {occList && occList?.length > 3 ? (
            <Slider
              {...settings}
              ref={sliderRef}
              className="occasion-based-pujas-slider-container occasion-slick7"
            >
              {occList.map((item, index) => (
                <div
                  key={item.id}
                  onClick={() => {
                    localStorage.setItem("pujaId", item.pujaId);
                    let navigateTo = "poojas/telugu/" + item?.pujas?.url_name;
                    window.open(navigateTo, "_self");
                  }}
                >
                  <div key={index}>
                    <div className="occasion-based-card">
                      {jsonData[item.pujas.name] ? (
                        <img
                          src={jsonData[item.pujas.name]}
                          width="100%"
                          height="100%"
                          className="cardImage"
                          alt={`${item.name}-image`}
                        />
                      ) : (
                        <img
                          src="assets/images/default-no-puja-image.png"
                          width="100%"
                          height="100%"
                          className="cardImage"
                          alt={`${item.name}-image`}
                        />
                      )}
                      <h4 className="puja-title">{item.pujas.name}</h4>
                      <p className="puja-desc">{item.pujas.description}</p>
                        <img className="gold-line" src="assets/images/gold-line.png" alt="gold-line" />
                       
                      <div className="bottom-price-btn">
                        <button className="puja-book-button">Know More</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="otherOccasionalCards">
            {occList.map((item, index) => (
              <div
                key={item.id}
                onClick={() => {
                  localStorage.setItem("pujaId", item.pujaId);
                  let navigateTo = "poojas/telugu/" + item?.pujas?.url_name;
                  window.open(navigateTo, "_self");
                }}
              >
                <div key={index}>
                  <div className="occasion-based-card">
                    {jsonData[item.pujas.name] ? (
                      <img
                        src={jsonData[item.pujas.name]}
                        width="100%"
                        height="100%"
                        className="cardImage"
                        alt={`${item.name}-image`}
                      />
                    ) : (
                      <img
                        src="assets/images/default-no-puja-image.png"
                        width="100%"
                        height="100%"
                        className="cardImage"
                        alt={`${item.name}-image`}
                      />
                    )}
                    <h4 className="puja-title">{item.pujas.name}</h4>
                    <p className="puja-desc">{item.pujas.description}</p>
                    <img className="gold-line" src="assets/images/gold-line.png" alt="gold-line" />
                    <div className="bottom-price-btn">
                      <button className="puja-book-button">Know More</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          )}
        </div>

        <div class="see-more-pujas">
          <div className="textpooja">Would like to see more pujas?</div>
          <button
            class="view-all-pujas"
            onClick={() => localStorage.setItem("tab", tab)}
          >
            <a href="/offerings" class="viewallbut">
              View All Pujas
            </a>
          </button>
        </div>

        <div class="How-its-work-container-mblsec">
          <h2 class="home-headings occasion-based-puja-header">How it Works</h2>
          <div class="how-it-works-container how-it-works-container-mblsec">
            <div class="how-it-works-sec-card how-it-works-sec-card-mblsec">
              <img src="assets/images/special-notes.png" alt="" />
              <p>
                Select which puja you wish to perform. Mention all your custom
                requirements in special notes (or) in call with pujari.
              </p>
            </div>
            <img class="hiw-arrow" src="assets/images/arr.png" alt="" />
            <div class="how-it-works-sec-card how-it-works-sec-card-mblsec">
              <img src="assets/images/pujari-chat-img.png" alt="" />
              <p>
                After receiving your booking the pujari and team will connect
                with you and make the appropriate plan for you.
              </p>
            </div>
            <img class="hiw-arrow" src="assets/images/arr.png" alt="" />
            <div class="how-it-works-sec-card how-it-works-sec-card-mblsec">
              <img src="assets/images/resheduled-img.png" alt="" />
              <p>
                If you can't find the puja you are looking for / OR / you wish
                to reschedule or cancel - Simply contact us via chat and we will
                help you out.
              </p>
            </div>
          </div>
        </div>
        <div class="frthcontsec">
          <div class="frthbtmcontsec">
            <div class="container">
              <h4>Essence of Karishye</h4>
              <div class="videosec">
                <video
                  width="100%"
                  controls="true"
                  loop=""
                  muted=""
                  data-origwidth="100"
                  data-origheight="0"
                >
                  <source
                    src="assets/Essence-of-karishye_with-subs-reduced-size-1.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>

        <div class="fifthcontsec">
          <div class="">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <p>
                  Establish a connect with the divine. Let it naturally blossom
                  into a beautiful, permanent relationship giving you all the
                  shade and support you will ever need.
                </p>
              </div>
              <div class="col-lg-6 col-md-6">
                <img class="tree-img" src="assets/images/rytimg.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp />
    </>
  );
}
